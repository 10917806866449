import React from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MaintenanceImage from '@/img/site-under-construction.svg';
import { Heading, Paragraph } from '../theme/text-variants';
import { colorTokens } from '../theme/color-tokens';
import { colors } from '../theme/colors';

const theme = createTheme({});

const Maintenance = () => {
  return (
    <ThemeProvider theme={theme}>
      <Stack height="100vh" justifyContent="center">
        <Stack gap="16px" alignItems="center" padding="16px">
          <MainImage />
          <Heading textAlign="center" size="xl" color={colorTokens.textPrimary}>
            All New Jatango
          </Heading>
            <Paragraph
            //   borderRadius="4px"
            //   p="8px"
            //   border="1px solid #000"
              textAlign="center"
              size="lg"
              fontWeight="bold"
              color={colors.black}
            >
              Coming Soon
            </Paragraph>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default Maintenance;

const YellowPrompt = styled(Box)(() => ({
  backgroundColor: '#FFD32B', //No color token exists for this color yet
  padding: '8px',
  borderRadius: '4px'
}));

const MainImage = styled(MaintenanceImage)(() => ({
  width: '400px',
  height: '400px',

  ['@media (max-width: 420px)']: {
    width: '90vw',
    height: '90vw'
  }
}));